.parking-session-detail {
  min-width: 25%;

  &-Started {
    min-width: 55%;
  }

  &__heading {
    font-style: normal;
    font-weight: 300 !important;
    font-size: 12px;
    line-height: 14px;
    line-height: 14px;

    letter-spacing: 1px;
  }

  &__content {
    font-weight: 600;
    font-size: 16px;
  }
}
