.header {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 20px;

  .user_account {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .app_name {
    text-decoration: none;
    :active {
      color: #000000;
    }
  }
  .logout {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
