.page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  margin: 15px;
  gap: 15px;

  & > * {
    flex-basis: 100%;
  }
}
