@use "shared/_fonts" as fonts;

.car-park-section {
    text-align: left;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;

    &-heading {
        padding: 0px 5px 5px 5px;
        border-bottom: 1px solid rgba(255, 216, 63, 0.4);

        .car-park-name {
            font-weight: 600;
            font-size: 18px;
        }

        .car-park-address {
            font-size: 16px;
            font-weight: 400;
        }
    }

    &-sessions {
        padding: 15px 5px 5px 5px;
    }
}
