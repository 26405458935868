.account-registration-page {
 
  .language-selector {
    text-align: left;
    margin-top: 16px;
    font-size: 16px !important;
    font-weight: 400 !important;
     
  }
  .MuiMenu-root{
    background: linear-gradient(180deg, rgb(255, 216, 63) 0%, rgb(255, 176, 57) 100%);

  }
  .phone-code-input {
    width: 100% !important;
    background: none !important;
    border: none !important;
    border-bottom: 1.5px solid black !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 0 !important;
    text-align: left !important;
    color: black !important;
  }

  .phone-code-input::placeholder {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.2509803922) !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }

  .react-tel-input .form-control:focus {
    box-shadow: none !important;
  }
  .react-tel-input .selected-flag:focus:before,
  .react-tel-input .selected-flag.open:before {
    box-shadow: none !important;
    border: none !important;
  }

  .country-list {
    text-align: left !important;
  }

  .react-tel-input .selected-flag {
    padding: 0 !important;
  }

  .react-tel-input .form-control {
    margin-top: 12px;
    padding: 12px 10px 5.8px 50px !important;
  }
  .special-label {
    background: none !important;
    display: block;
    top: 0px;
    left: -4px;
    font-weight: 500 !important;
    font-size: 11px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }

  .react-tel-input .flag-dropdown {
    top: 14px;
  }
  .MuiInput-input {
    font-size: 16px;
    font-weight: 500;
    background-clip: text;
  }

  .MuiFormLabel-root {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #00000040 !important;

    &.MuiFormLabel-filled,
    &.Mui-focused {
      transform: translate(0, 0) scale(0.7) !important;
      color: black !important;
    }
  }

  .MuiInput-underline {
    &:before {
      border-bottom: 1.5px solid black !important;
    }

    &:after {
      border-bottom: none !important;
    }
  }

  .flag-dropdown {
    .divider {
      margin: 0;
      background: none;
      border: none;
    }
  }
}
