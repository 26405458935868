$default: transparent !default;
$default-hover: white !default;
$default-active: #c68b01 !default;
$default-contrast: black !default;
$default-border: black !default;
$default-border-active: black !default;

$dark: black !default;
$dark-hover: white !default;
$dark-active: #c68b01 !default;
$dark-contrast: white !default;
$dark-border: black !default;
$dark-border-active: black !default;

$disabled: transparent;
$disabled-contrast: black;
$disabled-border: #00000017;
