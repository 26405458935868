.button-anchor {
  display: inline;
  padding: 0;
  text-align: inherit;
  color: inherit;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: none;
    border: none;
    outline: none;
  }
}
