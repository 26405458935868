.multi-line-input-box {
  margin-bottom: 16px;
  height: auto;
  text-align: left;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #000;
    background-color: white !important;
    background-clip: content-box !important;
  }

  .input-label {
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .input-value {
    position: relative;
    display: flex;
    width: 100%;

    &.input-error {
      .visible-input {
        border-color: red;
        background-color: transparent;
      }
    }
    .visible-input {
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-size: 12px;
      background-color: transparent;
      padding: 5px 16px 15px 2px;
      // With is 100% - 30(2*16)px(padding)
      width: 100%;
      height: 20px;
      border: 0;
      font-size: 16px;
      line-height: 20px;

      &:focus {
        outline: none !important;
      }
      ::placeholder {
        color: #010101;
      }

      &.with-suffix {
        padding-right: 16px;
      }
    }

    .suffix-icon-wrapper {
      float: right;
      width: 30px;
      height: 74%;
      background-color: transparent;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      right: -2px;
      top: 1px;

      .suffix-icon {
        position: absolute;
        right: 3%;
        top: 33%;
        width: 17px;
        height: 13px;

        &.text-hide {
          top: 35%;
        }

        img {
          width: auto;
        }
      }
    }
  }

  .error-msg {
    color: red;
    text-align: center;
    display: block;
  }
}
