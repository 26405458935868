.input-select {
  :focus {
    background: transparent !important;
  }

  .MuiFormLabel-root {
    font-size: 16px;
    font-weight: 400;
    &.MuiFormLabel-filled,
    &.Mui-focused {
      transform: translate(0, 0) scale(0.8) !important;
      color: black !important;
    }
  }

  :before {
    border: none !important;
  }

  .MuiInput-underline {
    &:before {
      border-bottom: 3px solid black !important;
    }

    &:after {
      border-bottom: none !important;
    }
  }
}
