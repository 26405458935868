.subscription-list-item {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 5px;
  text-align: left;
  position: relative; // Needed for absolute positioning

  .secondary-text {
    font-size: 12px;
  }

  .expand-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 15px;
  }

  .subscription-list-item-expanded {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .subscription-list-item-cancel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    font-size: 14px;
    border: 2px solid black;
    color: black;
    border-radius: 20px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    text-transform: capitalize;

    &:hover {
      background: #f8f8f8;
    }
  }

  .subscription-list-item-pay-button {
    height: auto;
    border: 1px solid grey;
    font-size: 14px;
    padding: 12px;
    text-decoration: none;
    color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 10px;

    span {
      display: flex;
    }
  }
}
