.payment-complete-page {
  &__icon {
    display: flex;
    justify-content: center;
  }

  &__heading-message {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 0 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 1px;
  }

  &__payment-message {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin: 0 0 10px;
  }

  .input-text .MuiFormLabel-root {
    font-size: 30px !important;
  }

  .input-text .MuiInput-input {
    font-size: 30px !important;
  }
}
