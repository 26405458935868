.terms-of-use-modal {
  h2 {
    text-align: center;
    padding-top: 20px;
  }
  &__content {
    text-align: left;
    padding: 20px;
  }
  .MuiDialog-paperFullScreen {
    background: linear-gradient(
      180deg,
      rgb(255, 216, 63) 0%,
      rgb(255, 176, 57) 100%
    );
  }
}
