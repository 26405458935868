.input-text {
  .MuiInput-input {
    font-size: 30px;
    font-weight: 500 !important;
    background-clip: text;
  }

  .MuiFormLabel-root {
    font-size: 30px;
    font-weight: 500;
    color: #00000040 !important;
    &.MuiFormLabel-filled,
    &.Mui-focused {
      transform: translate(0, 0) scale(0.4) !important;
      color: black !important;
    }
  }

  .MuiInput-underline {
    &:before {
      border-bottom: 3px solid black !important;
    }

    &:after {
      border-bottom: none !important;
    }
  }
}
