.dashboard-page {
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .tab-heading {
      cursor: pointer;
      font-size: 22px;
      text-align: center;
      margin-bottom: 20px;
    }

    .active {
      border-bottom: 2px solid #000000;
      font-weight: 600;
    }
  }
}
