.membership-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 10px;
    margin-bottom: 5px;
    text-align: left;
    .secondary-text {
      font-size: 12px;
    }

  }

