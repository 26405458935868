@use 'shared/colors' as colors;

$anchor-font-size: 18px !default;
$anchor-font-weight: 500 !default;
$anchor-font-weight--hover: 500 !default;

.anchor {
  position: relative;

  &:not(.button) {
    text-decoration: underline;
    font-size: $anchor-font-size;

    &.default {
      color: colors.$dark;
      font-weight: $anchor-font-weight;

      &:active,
      &:visited {
        color: colors.$dark;
      }

      &:hover {
        color: colors.$dark;
        font-weight: $anchor-font-weight--hover;
      }
    }
  }
}
