.operator-parking-sessions {
  margin: 20px 0 40px 0;

  .operator-name {
    text-align: center;
    font-weight: 600;
    line-height: 30px;
    margin-top: 15px;
  }

  .payment-total {
    font-weight: 300;
    margin-top: 16px;
    line-height: 30px;
  }

  .pay-button {
    width: 250px;
    margin-bottom: 20px;
  }

  .sticky-pay-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(255, 176, 57, 0.98);
    mix-blend-mode: normal;
    border-top: 1px solid #000000;
    border-radius: 10px 10px 0px 0px;
    z-index: 1;
  }
}
