.parking-details-page {
  // TODO:
  .refresh-link {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    margin: 20px 0;
    font-size: 18px;
  }

  .page-heading {
    margin-bottom: 4px;
  }
  h6 {
    font-weight: 600;
  }
  margin-bottom: 150px;
}
