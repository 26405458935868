@use 'shared/_fonts' as fonts;

body {
  min-height: 100vh;
  margin: 0;
  font-family: fonts.$font-family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255, 216, 63);
  background: linear-gradient(
    180deg,
    rgba(255, 216, 63, 1) 0%,
    rgba(255, 176, 57, 1) 100%
  );
}
::-webkit-scrollbar {
  width: 5px;
  background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
