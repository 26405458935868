.main {
  position: relative;
  .close-icon {
    position: absolute;
    top: -15px;
    right: -15px;
  }
  .membership {
    height: 70vh;
    overflow: auto;
    .membership-container {
      .membership-list {
        .membership-list-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 5px;
          padding: 10px;
          margin-bottom: 5px;
          .secondary-text {
            font-size: 12px;
          }
          textarea {
            background-color: transparent;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          }
          .membership-list-item-apply-button {
            height: 8px;
            border: 1px solid grey;
            font-size: 14px;
            padding: 12px;
          }
        }
      }
    }
  }
}
