.payment-confirmation-message {
  &__icon {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
