form.form.add-vehicle-form {
  .input-select {
    width: 100%;
    position: relative;
    .MuiFormLabel-root {
      position: absolute;
      font-size: 16px;
      font-weight: 500;
      color: #00000040;
      top: -14px;
    }
    .MuiSelect-select {
      font-size: 30px;
      min-height: unset;
      padding-bottom: 12px;
    }
  }

  .country-selector {
    width: 100% !important;
    // font-size: 25px !important;
    font-weight: 500 !important;
    color: #00000040 !important;
  }
  .MuiInputBase-root {
    // margin: 0px;
  }
}
