@use 'shared/breakpoints' as breakpoints;
@use 'shared/colors' as colors;

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  padding: 15px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &.default {
    color: colors.$default-contrast;
    background-color: colors.$default;
    border: 3px solid colors.$default-border;

    &:hover {
      @media (min-width: breakpoints.$large) {
        background-color: colors.$default-hover;
      }
    }

    &.active {
      border-color: colors.$default-border-active;
    }
  }

  &.dark {
    color: colors.$dark-contrast;
    background-color: colors.$dark;
    border: 3px solid colors.$dark-border;

    &:hover {
      @media (min-width: breakpoints.$large) {
        color: colors.$dark;
        background-color: colors.$dark-hover;
      }
    }

    &.active {
      border-color: colors.$dark-border-active;
    }
  }

  &.ghost {
    color: black;
    background-color: rgba(0, 0, 0, 0.1);
    border: 3px solid transparent;

    &:hover {
      color: colors.$dark;
      background-color: rgba(0, 0, 0, 0.14);
    }

    &.active {
      border-color: colors.$dark-border-active;
    }
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &[disabled] {
    cursor: inherit;
    color: colors.$disabled-contrast !important;
    background-color: colors.$disabled !important;
    border: 3px solid colors.$disabled-border !important;

    &:hover {
      color: colors.$disabled-contrast !important;
      background-color: colors.$disabled !important;
    }
  }
}
